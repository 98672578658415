import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'gifts',
  formId: 'UK%20general%20gifts',
  title: 'Gifts',
  subTitle: 'The gift of giving!',
  icon: '/icons/shopping.svg',
  image: '/assets/images/sunrise/gifts_supermercat.png',
  theme: 'sunrise-gifts',
  sections: [
    {
      sectionId: 'hero',
      label: 'Gifts',
      title: 'The gift of giving!',
      description:
        "Gift giving is contagious. It's just a way of life. With birthdays, anniversaries, weddings, religious celebrations, house warmings, births and just about any other special occasion you can think of - comes gifting. You’ll find everything you need here!",
      image: '/assets/images/sunrise/gifts_supermercat.png',
      icon: '/icons/gifts.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'Gifts in all sizes and shapes!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/gifts/service_1.svg',
          title: 'Anniversary gifts',
          description: '',
        },
        {
          icon: '/assets/images/services/gifts/service_2.svg',
          title: 'Big B-days gifts',
          description: '',
        },
        {
          icon: '/assets/images/services/gifts/service_3.svg',
          title: 'Seasonal gifts',
          description: '',
        },
        {
          icon: '/assets/images/services/gifts/service_4.svg',
          title: 'Appreciation gifts',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'A few steps towards the perfect gift(s)',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/gifts/journey_1.svg',
          title: 'Tell us your wishes',
          description: 'We have a vast variety of everything and something that will fit your needs perfectly.',
        },
        {
          icon: '/assets/images/services/gifts/journey_2.svg',
          title: 'Receive proposal',
          description: 'We’ll create a detailed proposal to make sure you give the perfect gift(s).',
        },
        {
          icon: '/assets/images/services/gifts/journey_3.svg',
          title: 'Accept!',
          description: 'That’s it! Hopefully you feel a tad like Santa now. ',
        },
        {
          icon: '/assets/images/services/gifts/journey_4.svg',
          title: 'Spoil someone rotten',
          description: 'Enjoy the praise and “thank yous”.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies love gifts from Good Monday!',
      description: '',
      image: '/assets/images/services/gifts/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-bulb-outline',
          title: 'Inspiration',
          description: 'We’ve been doing this for a long time and have a ton of ideas ready for you.',
        },
        {
          icon: 'eva eva-clock-outline',
          title: 'Less hassle',
          description:
            'Finding the right gift takes up a lot of headspace and finding something personal and within budget is really time consuming. ',
        },
        {
          icon: 'eva eva-gift-outline',
          title: 'From idea to gift',
          description: 'We make all your good intentions come true.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that Good Monday can take care of all your Christmas gifts including wrapping and cards?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
